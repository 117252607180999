import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import SEO from "../components/seo"
import HoverButton from "../components/buttons/HoverButton"

const Content = styled.div`
  text-align:center;
  padding: 50px;

  h1 {
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }
`

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Content>
      <h1>Page Not Found</h1>
      <p>
        Sorry, looks like something went wrong.<br />
        Please let us know.<br />
        800-863-0107<br />
      </p>
      <p>
        <Link to="/">
          <HoverButton
            color="blue"
            label="Back to Home Page"
          />
        </Link>
      </p>
    </Content>
  </>
)

export default NotFoundPage
